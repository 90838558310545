import { type NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function RootPage (): NextPage {
  const router = useRouter();

  useEffect(() => {
    void router.push('/recent-activity');
  }, []);

  return null;
}
